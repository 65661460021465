<script>
import Layout from "../../layouts/horizontal";
import PageHeader from "@/components/page-header";
import { mapGetters, mapActions } from 'vuex';
import http from '@/oauth-client'
import Multiselect from "vue-multiselect";

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect
  },
  data() {
    
    return {
      title: 'Accounts',
      accountId: null,
      processing: false,
      riskProfiles: [],
      accountRiskProfiles: [],
      riskProfileModalShowing: false,
      riskProfileModalInitializing: false,
      breadcrumbs: [
        
      ]
    };
  },
  created() {
    this.load();
  },
  watch: {
   
  },
  computed: {
    ...mapGetters('auth', {
        permissios: 'accountPermissions'
    }),
    ...mapGetters('accounts', {
        loading: 'loading',
        items: 'items'
    })
  },
  methods: {
     ...mapActions('accounts', {
        load: 'load'
    }),
    async showRiskProfileModal(accountId) {
      this.accountId = accountId;
      this.riskProfileModalShowing = true;
      this.riskProfileModalInitializing = true;
      await this.loadRiskProfiles();
      await this.loadAccountRiskProfiles();
      this.riskProfileModalInitializing = false;
    },
    hideRiskProfileModal() {
      this.riskProfileModalShowing = false;
    },
    async saveRiskProfileAccessList() {
      const removed = this.origAccountRiskProfiles.filter(x => !this.accountRiskProfiles.find(a => a.id === x.id));
      const added = this.accountRiskProfiles.filter(x => !this.origAccountRiskProfiles.find(a => a.id === x.id));
      this.processing = true;
      for(let i = 0; i < removed.length; i++) {
        await http.delete('api/predefined-risk-profiles/' + removed[i].id + '/accounts/' + this.accountId);
      }
      for(let i = 0; i < added.length; i++) {
        await http.put('api/predefined-risk-profiles/' + added[i].id + '/accounts/' + this.accountId);
      }
      this.processing = false;
      this.hideRiskProfileModal();
    },
    async loadRiskProfiles() {
      const resp = await http.get('api/predefined-risk-profiles');
      this.riskProfiles = resp.data;
    },
    async loadAccountRiskProfiles() {
      const resp = await http.get('api/predefined-risk-profiles?companyId=' + this.accountId);
      this.accountRiskProfiles = resp.data.map(a => this.riskProfiles.find(x => x.id === a.id)).filter(a => a);
      this.origAccountRiskProfiles = resp.data;
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" :is-busy="loading"/>
    <div class="form-group text-right">
      <router-link
        :to="{name: 'accounts-edit', params: { id: 'new'}}"
        class="btn btn-secondary"
      >
        <i class="fa fa-plus mr-2"/>
        NEW RECORD
      </router-link>
    </div>
    <b-modal
      v-model="riskProfileModalShowing"
      title="Risk Profile - Access List "
      title-class="text-dark font-18"
      hide-footer
    >
      <form>
        <div v-if="riskProfileModalInitializing" class="p-y4 px-4 text-center">
          <i class="fa fa-spin fa-spinner" style="font-size: 2rem;"></i>
        </div>
        <div class="form-group" v-if="!riskProfileModalInitializing">
          <label for="solution">Available Risk Profiles</label>
          <multiselect 
            v-model="accountRiskProfiles" 
            track-by="id"
            label="name"
            value="id"
            placeholder="Select Risk Profiles"
            :options="riskProfiles" 
            :multiple="true">
          </multiselect>
          
        </div>
       
        <div>
          <b-button class="ml-1 float-left" @click="hideRiskProfileModal"> Close</b-button>
          <button type="button" @click="saveRiskProfileAccessList" class="btn btn-success float-right" :disabled="processing">
            <i class="fa fa-spin fa-spinner mr-2" v-if="processing"></i>
            Save
          </button>
        </div>
      </form>
    </b-modal>

    <div class="card p-0 cardc8">
      <div class="card-content m-3">
        <div class="table-responsive">
          <table class="table table-centered nowrap"> 
            <thead>
              <tr>
                <th style="width: 130px; max-width: 130px;min-width: 130px;" ></th>
                <th class="col-10">Name</th>
                <th class="col-2">Status</th>
                
              </tr>
            </thead>
            <tbody>
              <tr v-if="items.length === 0 && !loading">
                <td colspan="100">
                  <span class="text-muted">No records found.</span>
                </td>
              </tr>
              <tr v-for="account in items" :key="account.id">
                <td style="width: 130px; max-width: 130px; min-width: 130px;">
                  <router-link
                    v-if="permissios.allowManage"
                    class="mr-2"
                    :to="{name:'accounts-edit', params: {  id: account.id}}"
                  >
                    <i class="mdi mdi-pencil font-size-18"></i>
                  </router-link>
                  <router-link
                    title="Available Instruments"
                    :to="{name:'instrument-assignment', params: { targetType: 'accounts', targetId: account.id}}"
                  >
                    <i class="mdi mdi-tools font-size-18"></i>
                  </router-link>
                  <a href="javascript:void(0);" class="ml-2  text-primary" @click="showRiskProfileModal(account.id)">
                    <i class="mdi mdi-toolbox font-size-18"></i>
                  </a> 
                </td>
                <td class="col-10">{{account.name}}</td>
                <td class="col-2">
                  <span class="badge badge-warning" v-if="account.disabled">disabled</span>
                </td>
                
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
</template>