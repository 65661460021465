<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
}
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">
           <span  class="title-content">
              {{ title }}
              <small v-if="subtitle" class="ml-2"> {{subtitle}}</small>
              <span class="ml-2 fa fa-spin fa-spinner" v-if="isBusy"></span>
            </span>
        </h4>

        <div class="page-title-right  d-none d-lg-block">
          <b-breadcrumb v-if="items.length > 0">
            <b-breadcrumb-item 
              v-for="(item, $index) in items" 
              :key="$index" 
              :active="item.active"
              :to="item.to"
            >
              {{item.text}}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
